var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"width":"80%"},attrs:{"fluid":""}},[_c('v-layout',[_c('v-flex'),_c('v-flex',{staticClass:"text-right my-2"},[_c('v-btn',{staticClass:"custom-grey-border custom-bold-button",attrs:{"depressed":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-keyboard-backspace")]),_vm._v(" Back ")],1)],1)],1),_c('perfect-scrollbar',{staticClass:"scroll custom-box-top-inner-shadow",staticStyle:{"max-height":"85vh","position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-card',{staticClass:"custom-setting-height custom-grey-border remove-border-radius",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline grey lighten-4"},[_c('v-row',[_c('v-col',{staticClass:"py-0 my-auto",attrs:{"md":"6"}},[_c('h3',{staticClass:"fw-700",staticStyle:{"margin":"3.5px 0px"}},[_vm._v("Backlink Category (Industry Segment)")])]),(
							_vm.getPermission('backlink_category_settings:create') &&
							_vm.AllowAnyOforGetRoleNType([
								'master',
								'admin',
								'manager',
								'super_admin',
								'team_lead',
								'ateam_lead',
							])
						)?[_c('v-col',{staticClass:"text-right py-0",attrs:{"md":"6"}},[_c('v-btn',{staticClass:"my-auto",attrs:{"color":"red darken-4 mr-3","dark":"","min-height":"26","x-small":"","depressed":"","tile":""},on:{"click":_vm.AddCategory}},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"white--text my-auto",attrs:{"color":"blue darken-4","depressed":"","tile":"","disabled":_vm.pageLoading || !_vm.formValid},on:{"click":_vm.updateOrcreate}},[_c('span',{staticClass:"font-size-16 font-weight-600"},[_vm._v("Save")])])],1)]:_vm._e()],2)],1),_c('v-card-text',{staticClass:"p-6 font-size-16"},[_c('v-form',{ref:"backlink_category",staticStyle:{"height":"calc(100vh - 245px)","overflow-y":"auto","overflow-x":"hidden"},attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateOrcreate.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_vm._l((_vm.categorylist),function(type,index){return _c('v-row',{key:type.id,staticClass:"list-item"},[_c('v-col',{attrs:{"md":_vm.AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
									? 11
									: 12}},[_c('v-text-field',{ref:`backlink_category_${index}`,refInFor:true,staticClass:"textCapitalize",class:{
									required: !type.title,
								},attrs:{"hide-details":"","outlined":"","placeholder":"Category Type","readonly":type.title == 'backlink category' ||
									!_vm.getPermission('backlink_category_settings:update') ||
									!_vm.AllowAnyOforGetRoleNType([
										'master',
										'admin',
										'manager',
										'super_admin',
										'team_lead',
										'ateam_lead',
									]),"rules":[_vm.vrules.required(type.title, 'Activity Type')]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();index + 1 === _vm.categorylist.length && _vm.AddCategory()},"change":(e) => _vm.updateCategory(e, index)},model:{value:(type.title),callback:function ($$v) {_vm.$set(type, "title", $$v)},expression:"type.title"}})],1),(
								_vm.getPermission('backlink_category_settings:delete') &&
								_vm.AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead'])
							)?_c('v-col',{attrs:{"md":"1"}},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"deep-orange"},on:{"click":function($event){return _vm.deleteCommon(type.id, index)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Click here to delete")])])],1):_vm._e()],1)}),(_vm.categorylist.length < 1)?[_c('p',{staticClass:"m-0 row-not-found text-center"},[_c('img',{staticClass:"row-not-found-image",attrs:{"src":_vm.$assetURL('media/error/empty.png')}}),_vm._v(" Uhh... There are no backlink category at the moment. ")])]:_vm._e()],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }